export class ErrorMessage extends HTMLElement {
  static get observedAttributes() {
    return ['message', 'bold', 'icon-align']
  }

  constructor(message?: string, iconAlign?: string, bold?: string) {
    super();
    if (message) {
      this.setAttribute('message', message);
    }

    if (iconAlign) {
      this.setAttribute('icon-align', iconAlign);
    }

    if (bold) {
      this.setAttribute('bold', bold);
    }
  }

  connectedCallback() {
    this.reader();
  }

  attributeChangedCallback(name: string, _oldValue: string, _newValue: string) {
    if (['message', 'bold', 'icon-align'].includes(name) && this.isConnected) {
      this.reader();
    }
  }

  private reader() {
    const message = this.getAttribute('message') || '';

    const iconAlign = this.getAttribute('icon-align') || 'items-center';
    const bold = this.getAttribute('bold') === 'false' ? '' : 'font-bold';

    this.innerHTML = `
    <div class='min-h-6 flex ${iconAlign} my-1'>
      <div class="flex items-center justify-center">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"><path fill="#ec4c4c" d="M10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10zm-1-7v2h2v-2zm0-8v6h2V5Z"/></svg>
      </div>
      <div class="pl-2 error-text text-sm ${bold}">${message}</div>
    </div>
    `;
  }
}

export type EventListener = {
  eventName: string;
  target: HTMLElement|Window;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  event: any;
  options?: boolean | AddEventListenerOptions;
}

export class EventHandler {
  private _listeners: Map<string, EventListener> = new Map();

  add(id: string, listener: EventListener) {
    if (this._listeners.has(id)) {
      return;
    }

    listener.target.addEventListener(listener.eventName, listener.event, listener.options);
    this._listeners.set(id, listener);
  }

  remove(id: string) {
    const listener = this._listeners.get(id);
    if (!listener) {
      return;
    }

    listener.target.removeEventListener(listener.eventName, listener.event, listener.options);
    this._listeners.delete(id);
  }
}
